import { Badge, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslations } from 'next-intl';
import { EVENT_BOOK_PAGES, EVENT_TYPE } from '../../constants/enums';
import { useAppDispatch } from '../../hooks/store';
import { setEvent } from '../../store/eventSlice';
import { openEventBookModal } from '../../store/modalsSlice';
import { EventResponse } from '../../types/events';

interface Props {
  event: EventResponse;
}

export default function EventListItem(props: Props) {
  const { event } = props;

  const t = useTranslations('QueenHub');
  const dispatch: any = useAppDispatch();

  const startDateTime = new Date(event.startDateTime);
  const startDate = moment(startDateTime).format('dddd Do');
  const startTime = moment(startDateTime).format('h:mmA');

  function onClickViewDetails() {
    dispatch(setEvent(event));
    dispatch(openEventBookModal(EVENT_BOOK_PAGES.DETAILS));
  }

  return (
    <>
      <Flex direction="row">
        <Flex direction="column">
          <Flex gap="0.313rem">
            <Badge
              bgColor="black"
              textColor="white"
              w="fit-content"
              mb="0.313rem"
              data-test-id="license-tag"
            >
              {event.licenseType}
            </Badge>
            {event.type === EVENT_TYPE.WORKSHOP && (
              <Badge
                bgColor="gray.50"
                textColor="pink.500"
                w="fit-content"
                mb="0.313rem"
                data-test-id="workshop-tag"
              >
                {event.type}
              </Badge>
            )}
          </Flex>
          <Text fontWeight={700} fontSize="1.25rem" mb={0} data-test-id="routine-details">
            {event.routine.artist} - {event.routine.songName}
          </Text>
          <Text
            fontSize="1rem"
            mb={0}
            data-test-id="with-boss"
          >{`With SOS ${event.boss.firstName}`}</Text>
          <Text mb={0} data-test-id="event-date">{`${startDate} @ ${startTime}`}</Text>
          <Text color="pink.500" data-test-id="event-location">
            {event.location.name}
          </Text>
        </Flex>
        <Spacer />
        <Flex direction="column" justifyContent="flex-start" gap="0.375rem" mt="1rem">
          <Text mb={0} fontWeight={700} fontSize="1.5rem" data-test-id="price">
            £{event.ticketPrice}
          </Text>
          <Button
            colorScheme="pink"
            w="full"
            size="sm"
            onClick={onClickViewDetails}
            data-test-id="view-event-details-button"
          >
            {t('findClass.eventsList.viewDetailsButton')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

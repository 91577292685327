import { List, ListItem } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { resultListItemStyle, resultListStyle } from '../../styles/global';
import { EventResponse } from '../../types/events';
import EventListItem from './EventListItem';

const classResultListItemStyle = {
  ...resultListItemStyle,
  paddingLeft: 0,
  marginLeft: 0,
  cursor: 'pointer',
} as const;

const bookButtonStyle = {
  width: { base: 10, md: 24 },
  marginTop: { base: 0, md: '0.875rem' },

  '&:last-child': {
    marginBottom: '-2rem !important',
  },

  '> svg': {
    display: { base: 'block', md: 'none' },
  },
} as const;

export interface Props {
  events: EventResponse[];
  setMapCenter: Dispatch<SetStateAction<{ lat: number; lng: number } | null>>;
  setMapZoom: Dispatch<SetStateAction<number | null>>;
}

export default function EventsList(props: Props) {
  const { events, setMapCenter, setMapZoom } = props;

  function setMapToEventPlace(event: EventResponse): void {
    setMapCenter({
      lat: event.location.geometry.location.lat,
      lng: event.location.geometry.location.lng,
    });
    setMapZoom(15);
  }

  return (
    <>
      <List sx={{ ...resultListStyle, paddingLeft: 0, paddingTop: 6 }}>
        {events.map((event, index) => (
          <ListItem
            sx={classResultListItemStyle}
            key={`class_${index}`}
            data-test-id="class-list-item"
            onClick={() => setMapToEventPlace(event)}
          >
            <EventListItem event={event} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
